import {API_BASE_URL} from '../config';
import {axiosGet, axiosPost, axiosPut, axiosDelete} from '../axios';
import { del, get, post, put } from './utils';

export const DIVISION_SUCCESS = 'DIVISION_SUCCESS';
export const fetchSuccess = data => ({
    type: DIVISION_SUCCESS,
    data
});

export const DIVISION_ERROR = 'DIVISION_ERROR';
export const fetchError = error => ({
    type: DIVISION_ERROR,
    error
});

export const postDivision = (division) => (dispatch) => {
    return post(dispatch, `${API_BASE_URL}/divisions`, division)
        .then(({division}) => dispatch(fetchSuccess(division)));
};

export const getDivisions = () => (dispatch) => {
    return get(dispatch, `${API_BASE_URL}/divisions`)
        .then(({divisions}) => dispatch(fetchSuccess(divisions)));
};

export const getDivision = (id_division) => (dispatch) => {
    return get(dispatch, `${API_BASE_URL}/divisions/${id_division}`)
        .then(({division}) => dispatch(fetchSuccess(division)));
};

export const putDivision = (division) => (dispatch) => {
    return put(dispatch, `${API_BASE_URL}/divisions/${division.id_division}`, division)
        .then(({division}) => dispatch(fetchSuccess(division)));
};

export const deleteDivision = (id_division) => (dispatch) => {
    return del(dispatch, `${API_BASE_URL}/divisions/${id_division}`)
        .then(({division}) => dispatch(fetchSuccess(division)));
};