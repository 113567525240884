import {API_BASE_URL} from '../config';
import {get} from "./utils";

export const QUALITY_SUCCESS = 'QUALITY_USAGE_CLASS_SUCCESS';
export const fetchSuccess = data => ({
    type: QUALITY_SUCCESS,
    data
});

export const QUALITY_ERROR = 'QUALITY_USAGE_CLASS_ERROR';
export const fetchError = error => ({
    type: QUALITY_ERROR,
    error
});

export const getQualitiyUsageClasses = () => (dispatch) => {
    return get(dispatch, `${API_BASE_URL}/quality_usage_classes/`)
        .then(({qualities}) => dispatch(fetchSuccess(qualities)))
};

export const getPublicQualityUsageClasses = (token) => (dispatch) => {
    return get(dispatch, `${API_BASE_URL}/ext/quality_usage_classes/${token}`)
        .then(({qualities}) => dispatch(fetchSuccess(qualities)))
};

export const getQualityUsageClass = (id_quality_usage) => (dispatch) => {
    return get(dispatch, `${API_BASE_URL}/quality_usage_classes/${id_quality_usage}`)
        .then(({quality}) => dispatch(fetchSuccess(quality)));
};
