import { List } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { clearAuth, refreshToken } from '../actions/auth';
import { clearToken } from '../local-storage';

import CardMedia from "@material-ui/core/CardMedia";
import { withNamespaces } from "react-i18next";
import { setCompany } from '../actions/parameters';
import { getUserCompanies } from '../actions/users';
import isAuthorized from "../security/isAuthorized";
import { ImagePath } from "../templates/style";



const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    primary: {
        backgroundColor: theme.palette.primary.main + " !important"
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    list: {
        width: 250,
    },
    account: {
        width: '40px',
        height: '40px',
    },
    logo: {
        width: 150,
        height: 50
    },
});

// Variables for language
let i18n;
let t;

/**
 * HeaderBar et menu déroulant de l'app
 */
export class HeaderBar extends React.Component {

    state = {
        anchorEl: false,
        drawer: false,
        hasChangeCompagny: false,
    }

    componentWillMount() {
        i18n = this.props.i18n

        t = this.props.t
    }

    componentDidMount() {
        if ((this.props.user !== "PUBLIC" && this.props.user !== "Public" && this.props.user !== null) && this.props.loggedIn) {
            this.props.dispatch(getUserCompanies(this.props.user.id_user));
        }
    }

    componentDidUpdate(prevProps) {
        if ((this.props.user !== "PUBLIC" && this.props.user !== "Public" && this.props.user !== null) && this.props.loggedIn) {
            if (this.props.loggedIn && (!Array.isArray(prevProps.userCompanies) || !Array.isArray(this.props.userCompanies) || (this.props.roleValue !== prevProps.roleValue))) {

                this.props.dispatch(getUserCompanies(this.props.user.id_user));

            } else if (this.props.loggedIn && this.props.userCompanies.length > 0) {
                const id_company = this.state.id_company ? this.state.id_company : this.props.userCompanies[0].id_company;
                if (!this.state.id_company) {

                    if (localStorage.getItem('company')) {
                        this.setState({ id_company: localStorage.getItem('company') });
                    } else {
                        this.setState({ id_company: this.props.userCompanies[0].id_company });
                        localStorage.setItem('company', this.props.userCompanies[0].id_company);
                    }

                }
                this.props.dispatch(setCompany(id_company));
            }
        }
    }

    logOut() {
        this.props.dispatch(clearAuth());
        clearToken();
    }

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: false });
    };

    toggleDrawer = (open) => () => {
        this.setState({
            drawer: open,
        });
    };

    handleCompanyChange = ({ target: { name, value } }) => {
        this.setState({ hasChangeCompagny: true }, () => {
            this.props.dispatch(refreshToken(parseInt(value)));
            localStorage.setItem("company", value);
            this.setState({
                id_company: parseInt(value),
                hasChangeCompagny: false
            })
        })
    }

    changeLanguage(event) {
        i18n.changeLanguage(event.target.value);
    }

    renderCompagnie() {
        if (this.props.user !== "PUBLIC" || this.props.user !== "Public") {
            if (this.props.userCompanies) {
                return <Select native onChange={this.handleCompanyChange} value={this.state.id_company} inputProps={{ name: 'id_company', id: 'id_company', }} >
                    {this.props.userCompanies.map(function (item, i) {
                        return (
                            <option key={i} value={item.id_company}>{item.name}</option>
                        )
                    })}
                </Select>
            }
        }
    }

    render() {
        const { classes } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        if (this.props.user === "PUBLIC" || this.props.user === "Public") {
            return (// public pages headerbar
                <Route render={({ history }) => (
                    <div className={classes.root}>

                        <AppBar position="static" className={"no-print " + classes.primary}>

                            <style>
                                {`@media print {.no-print{display: none;}}`}
                            </style>

                            <Toolbar>
                                {/*<Box display={"block"} displayPrint={"none"}>*/}
                                {/*Screen Only (Hide on print only)*/}
                                {/*</Box>*/}

                                <Typography variant="h1" color="inherit" className={classes.grow} style={{ cursor: 'pointer' }}>
                                    <CardMedia
                                        component="img"
                                        alt="SylvaMet Logo"
                                        className={classes.logo}
                                        image={ImagePath.logoHeadBar}
                                        title="SylvaMet"
                                    />
                                </Typography>

                                <Select native onChange={this.changeLanguage} value={i18n.language}>
                                    <option key={'fr'} value={'fr'}>FR</option>
                                    <option key={'de'} value={'de'}>DE</option>
                                    <option key={'it'} value={'it'}>IT</option>
                                </Select>
                                <div>{this.props.user}</div>
                            </Toolbar>
                        </AppBar>
                    </div>
                )} />
            );
        } else { //Normal header bar
            return (
                <Route render={({ history }) => (
                    <div className={classes.root}>

                        <AppBar position="static" className={"no-print " + classes.primary}>

                            <style>
                                {`@media print {.no-print{display: none;}}`}
                            </style>

                            <Toolbar>
                                {/*<Box display={"block"} displayPrint={"none"}>*/}
                                {/*Screen Only (Hide on print only)*/}
                                {/*</Box>*/}


                                {this.props.loggedIn &&
                                    <IconButton className={classes.menuButton} onClick={this.toggleDrawer(true)} color="inherit" aria-label="Menu">
                                        <MenuIcon style={{ color: "white", fontSize: 35 }} />
                                    </IconButton>}
                                <Typography variant="h1" color="inherit" className={classes.grow} onClick={() => history.push('/')} style={{ cursor: 'pointer' }}>
                                    <CardMedia
                                        component="img"
                                        alt="SylvaMet Logo"
                                        className={classes.logo}
                                        image={ImagePath.logoHeadBar}
                                        title="SylvaMet"
                                    />
                                </Typography>

                                <Select native onChange={this.changeLanguage} value={i18n.language}>
                                    <option key={'fr'} value={'fr'}>FR</option>
                                    <option key={'de'} value={'de'}>DE</option>
                                    <option key={'it'} value={'it'}>IT</option>
                                </Select>

                                {(this.props.loggedIn) && this.renderCompagnie()}
                                {(this.props.loggedIn && this.state.hasChangeCompagny) ? history.push("/") : ""}
                                {(this.props.loggedIn) && <IconButton aria-owns={open ? 'menu-appbar' : undefined} aria-haspopup="true"
                                    onClick={this.handleMenu} color="inherit">
                                    <CardMedia
                                        component="img"
                                        alt={t("Mon profil")}
                                        className={classes.account}
                                        image={ImagePath.iconProfil}
                                        title={t("Mon profil")}
                                    />
                                </IconButton>}

                                {(this.props.loggedIn) && <Menu id="menu-appbar" anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={open}
                                    onClose={this.handleClose} >
                                    <ListItem style={{ outlineStyle: 'none' }}>
                                        <ListItemText primary={this.props.user.firstname + " " + this.props.user.lastname} secondary={this.props.user.email} />
                                    </ListItem>
                                    <MenuItem onClick={() => history.push('/profile')}>{t('Profile')}</MenuItem>
                                    <MenuItem onClick={() => this.logOut()}>{t('Déconnexion')}</MenuItem>
                                </Menu>}

                            </Toolbar>
                        </AppBar>
                        <Drawer open={this.state.drawer} onClose={this.toggleDrawer(false)}>
                            <div className={classes.list} tabIndex={0} role="button" onClick={this.toggleDrawer(false)} onKeyDown={this.toggleDrawer(false)} >
                                <List>
                                    <ListItem button key='Menu' onClick={() => history.push('/')}>
                                        <ListItemText primary={t("Menu")} />
                                    </ListItem>

                                    {isAuthorized("martelage", "R") && <ListItem button key='Martelage' onClick={() => history.push('/hammering')}>
                                        <ListItemText primary={t("Martelage")} />
                                    </ListItem>}
                                    {isAuthorized("cubage", "R") && <ListItem button key='Cubage' onClick={() => history.push('/measurement')}>
                                        <ListItemText primary={t("Cubage")} />
                                    </ListItem>}
                                    {isAuthorized("carte", "R") && <ListItem button key='Gestion des piles (carte)' onClick={() => history.push('/map')}>
                                        <ListItemText primary={t("Gestion des piles (carte)")} />
                                    </ListItem>}
                                    {isAuthorized("lot", "R") && <ListItem button key='Création de lots' onClick={() => history.push('/lot')}>
                                        <ListItemText primary={t('Création de lots')} />
                                    </ListItem>}
                                    <ListItem button key='Gestion des données' onClick={() => history.push('/data_manager/cuts')}>
                                        <ListItemText primary={t('Gestion des données')} />
                                    </ListItem>
                                    <ListItem button key='Réglages' onClick={() => history.push('/parameters/users')}>
                                        <ListItemText primary={t('Réglages')} />
                                    </ListItem>
                                    <ListItem button key='export' onClick={() => history.push('/export/cuts')}>
                                        <ListItemText primary={t('menu.page.export')} />
                                    </ListItem>
                                </List>
                            </div>
                        </Drawer>
                    </div>
                )} />
            );
        }
    }
}

HeaderBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    loggedIn: state.auth.app_user !== null,
    user: state.auth.app_user,
    roleValue: state.role.value,
    userCompanies: state.company.data || state.userCompany.data,
});

export default withNamespaces()(connect(mapStateToProps)(withStyles(styles)(HeaderBar)));