import {API_BASE_URL} from '../config';
import { del, get, post, put } from './utils';

export const DEDUCTION_TYPE_SUCCESS = 'DEDUCTION_TYPE_SUCCESS';
export const fetchSuccess = data => ({
    type: DEDUCTION_TYPE_SUCCESS,
    data
});

export const DEDUCTION_TYPE_ERROR = 'DEDUCTION_TYPE_ERROR';
export const fetchError = error => ({
    type: DEDUCTION_TYPE_ERROR,
    error
});

export const postDeductionType = (deduction_type) => (dispatch) => {
    return post(dispatch, `${API_BASE_URL}/deduction_types`, deduction_type)
        .then(({deduction_type}) => dispatch(fetchSuccess(deduction_type)));
};

export const getDeductionTypes = () => (dispatch) => {
    return get(dispatch, `${API_BASE_URL}/deduction_types/`)
        .then(({deduction_types}) => dispatch(fetchSuccess(deduction_types)));
};

export const getDeductionType = (id_deduction_type) => (dispatch) => {
    return get(dispatch, `${API_BASE_URL}/deduction_types/${id_deduction_type}`)
        .then(({deduction_type}) => dispatch(fetchSuccess(deduction_type)));
};

export const putDeductionType = (deduction_type) => (dispatch) => {
    return put(dispatch, `${API_BASE_URL}/deduction_types/${deduction_type.id_deduction_type}`, deduction_type)
        .then(({deduction_type}) => dispatch(fetchSuccess(deduction_type)));
};

export const deleteDeductionType = (id_deduction_type) => (dispatch) => {
    return del(dispatch, `${API_BASE_URL}/deduction_types/${id_deduction_type}`)
        .then(({deduction_type}) => dispatch(fetchSuccess(deduction_type)));
};