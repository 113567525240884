import {API_BASE_URL} from '../config';
import {axiosGet, axiosPost, axiosPut, axiosDelete} from '../axios';
import {ACTIONS} from "./_actions_types";
import {getUser} from "./users";
import { get } from './utils';

export const ROLE_SUCCESS = 'ROLE_SUCCESS';
export const fetchSuccess = data => ({
    type: ROLE_SUCCESS,
    data
});

export const SET_ROLE_VALUE = 'SET_ROLE_VALUE';
export const setRoleValue = value => ({
    type: SET_ROLE_VALUE,
    value
});

export const ROLE_ERROR = 'ROLE_ERROR';
export const fetchError = error => ({
    type: ROLE_ERROR,
    error
});

export const postRole = (role) => (dispatch, getState) => {

    axiosPost(`${API_BASE_URL}/roles`, role).then((res)=>{
        dispatch(getUser(res.data.id_user));
    })
};

export const getRoles = () => (dispatch) => {
    axiosGet(`${API_BASE_URL}/roles`).then((res) => {
        dispatch({
            type: ACTIONS.ROLES.GET_ALL,
            payload: res.data.roles
        })
    })
};

export const getRole = (id_role) => (dispatch) => {
    return get(dispatch, `${API_BASE_URL}/roles/${id_role}`)
        .then(({role}) => dispatch(fetchSuccess(role)));
};

export const putRole = (role) => (dispatch, getState) => {
    return axiosPut(`${API_BASE_URL}/roles/${role.id_user}/${role.id_company}`, role)
        .then((role) => {
            dispatch({
                type: ACTIONS.ROLES.PUT,
                payload: role.data.role
            })
        });
};

export const deleteRole = (id_user, id_company) => (dispatch) => {
    axiosDelete(`${API_BASE_URL}/roles/`, {id_user,id_company}).then(()=>{
        dispatch(getUser(id_user));
    })
};