import { ACTIONS } from '../actions/_actions_types';

const initialState = {
    app_user: null,
    loading: false,
    error: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.AUTH.AUTH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case ACTIONS.AUTH.AUTH_SUCCESS:
            return {
                ...state,
                loading: false,
                app_user: action.app_user,
            };
        case ACTIONS.AUTH.AUTH_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
                app_user: null,
            };
        case ACTIONS.AUTH.PUBLIC_AUTH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case ACTIONS.AUTH.PUBLIC_AUTH_SUCCESS:
            return {
                ...state,
                loading: false,
                app_user: 'PUBLIC'
            };
        case ACTIONS.AUTH.PUBLIC_AUTH_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
                app_user: null,
            };
    }
    return state;
}