import axios from 'axios';
import {clearToken} from "./local-storage";
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';
import i18next from "i18next";

let instance = axios.create();

instance.interceptors.request.use(function (config) {
    try {
        const authorization = instance.defaults.headers.common['Authorization']
        const token = localStorage.getItem("token");

        // console.log("request intercept token", token)
        // console.log("request intercept auth", authorization)
        
        if (token) {
            let decoded = jwt_decode(token);

            if (Date.now() / 1000 > decoded.exp) {

                clearToken();
                // TODO avoid reloading the page, favour a redirect to login or updating the store to logout
                window.location.href = '/login';
                window.location.reload(true);
            }
        }
    } catch(err) {
        console.log("request intercept token error", err)
    }

    return config;
}, function (error) {
    // console.log("axios error", error)
    return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {

    if (response.data.notif_message !== undefined && response.data.notif_message != null) {
        console.log('success notif', response.data.notif_message);
        toast.success(i18next.t(response.data.notif_message), {
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }

    return response;
}, function (error) {
    console.log('intercep');
    console.log('intercep error', error.response);
    if (error.response && error.response.data.notif_message !== undefined && error.response.data.notif_message != null && !error.response.data.notif_message.includes('null')) {
        console.log('error notif', error.response.data.notif_message);
        toast.error(i18next.t(error.response.data.notif_message), {
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }

    return Promise.reject(error);
});

export const setHeaders = (token, company) => {
    instance.defaults.headers.common['Authorization'] = token;
    instance.defaults.headers.common['X-Company'] = company;
};

export const axiosGet = (url) => {
    return instance.get(url);
};

export const axiosPost = (url, data) => {
    return instance.post(url, data);
};

export const axiosBlobPost = (url, data) => {
    return instance.post(url, data, {
        ...instance.defaults,
        responseType: 'blob',
        headers: {
            ...instance.defaults.headers,
            'Content-Type': 'application/json'
        }
    });
};

export const axiosPut = (url, data) => {
   return instance.put(url,data)
};

export const axiosDelete = (url, data) => {
    return instance.delete(url, {data:data})
};

