import {API_BASE_URL} from '../config';
import {get} from "./utils";

export const QUALITY_SUCCESS = 'QUALITY_USAGE_SUCCESS';
export const fetchSuccess = data => ({
    type: QUALITY_SUCCESS,
    data
});

export const QUALITY_ERROR = 'QUALITY_USAGE_ERROR';
export const fetchError = error => ({
    type: QUALITY_ERROR,
    error
});

export const getQualitiyUsages = () => (dispatch) => {
    return get(dispatch, `${API_BASE_URL}/quality_usages/`)
        .then(({qualities}) => dispatch(fetchSuccess(qualities)))
};

export const getPublicQualityUsages = (token) => (dispatch) => {
    return get(dispatch, `${API_BASE_URL}/ext/quality_usages/${token}`)
        .then(({qualities}) => dispatch(fetchSuccess(qualities)))
        .catch((err) => dispatch(fetchError(err)));
};

export const getQualityUsage = (id_quality_usage) => (dispatch) => {
    return get(dispatch, `${API_BASE_URL}/quality_usages/${id_quality_usage}`)
        .then(({quality}) => dispatch(fetchSuccess(quality)));
};
