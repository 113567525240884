import { SubmissionError } from 'redux-form';
import { axiosDelete, axiosGet, axiosPost, axiosPut } from '../axios';
import { displayErrorToast } from './toast';

// Boilerplate code for handling errors from the API.  If the error response
// contains JSON then we return a rejected promise containing the decoded
// JSON.  If the error doesn't contain JSON then we return a rejected promise
// containing the status text.  If there is no error then we continue with
// the promise chain.
export const normalizeResponseErrors = res => {
    if (res.statusText !== 'OK' && res.statusText !== "" && res.status >= 400) {
        if (
            res.headers['content-type'] &&
            res.headers['content-type'].startsWith('application/json')
        ) {
            // It's a nice JSON error returned by us, so decode it
            return Promise.reject(res.data);
        }
        // It's a less informative error returned by express
        return Promise.reject({
            code: res.status,
            message: res.statusText
        });
    }
    return res;
};

export const FETCH_ERROR = 'FETCH_ERROR';
export const fetchError = error => ({
    type: FETCH_ERROR,
    error
});

export const get = async (dispatch, url) => {
    return axiosGet(url)
        .then((res) => normalizeResponseErrors(res))
        .then(res => res.data)
        .catch(err => dispatch(handleError(err)));
};

export const post = async (dispatch, url, data) => {
    return axiosPost(url, data)
        .then(res => normalizeResponseErrors(res))
        .then(res => res.data)
        .catch(err => dispatch(handleError(err)));
};

export const put = async (dispatch, url, data) => {
    return axiosPut(url, data)
        .then(res => normalizeResponseErrors(res))
        .then(res => res.data)
        .catch(err => dispatch(handleError(err)));
};

export const del = async (dispatch, url) => {
    return axiosDelete(url)
        .then(res => normalizeResponseErrors(res))
        .then(res => res.data)
        .catch(err => dispatch(handleError(err)));
};

export const handleError = (err) => (dispatch) => {
    const { reason, message, location } = err;
    if (reason === 'ValidationError') {
        // Convert ValidationErrors into SubmissionErrors for Redux Form
        return Promise.reject(
            new SubmissionError({
                [location]: message
            })
        );
    } else {
        displayErrorToast(err.response.data.notif_message);
        dispatch(fetchError(err.response.data.notif_message));
    }
}
