import {API_BASE_URL} from '../config';
import { del, get, post, put } from './utils';

export const SORT1_SUCCESS = 'SORT1_SUCCESS';
export const fetchSuccess = data => ({
    type: SORT1_SUCCESS,
    data
});

export const SORT1_ERROR = 'SORT1_ERROR';
export const fetchError = error => ({
    type: SORT1_ERROR,
    error
});

export const postSort1 = (sort1) => (dispatch) => {
    return post(dispatch, `${API_BASE_URL}/sort1s`, sort1)
    .then(({sort1}) => dispatch(fetchSuccess(sort1)));
};

export const getSort1s = () => (dispatch) => {
    return get(dispatch, `${API_BASE_URL}/sort1s/`)
        .then(({sort1s}) => dispatch(fetchSuccess(sort1s)));
};

export const getSort1 = (id_sort1) => (dispatch) => {
    return get(dispatch, `${API_BASE_URL}/sort1s/${id_sort1}`)
        .then(({sort1}) => dispatch(fetchSuccess(sort1)));
};

export const putSort1 = (sort1) => (dispatch) => {
    return put(dispatch, `${API_BASE_URL}/sort1s/${sort1.id_sort1}`, sort1)
        .then(({sort1}) => dispatch(fetchSuccess(sort1)));
};

export const deleteSort1 = (id_sort1) => (dispatch) => {
    return del(dispatch, `${API_BASE_URL}/sort1s/${id_sort1}`)
        .then(({sort1}) => dispatch(fetchSuccess(sort1)));
};