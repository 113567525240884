import {API_BASE_URL} from '../config';
import {ACTIONS} from "./_actions_types";
import {axiosGet} from "../axios";

export const COUNTRY_SUCCESS = 'COUNTRY_SUCCESS';
export const fetchSuccess = data => ({
    type: COUNTRY_SUCCESS,
    data
});

export const COUNTRY_ERROR = 'COUNTRY_ERROR';
export const fetchError = error => ({
    type: COUNTRY_ERROR,
    error
});

export const getCountries = () => (dispatch) => {
    axiosGet(`${API_BASE_URL}/countries`).then((res)=>{
        dispatch({
            type:ACTIONS.COUNTRY.GET_ALL,
            payload:res.data.countries
        })
    });
};