import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import jwt_decode from 'jwt-decode';
import thunk from 'redux-thunk';

import { loadToken } from './local-storage';
import authReducer from './reducers/auth';
import departmentReducer from './reducers/department';
import countryReducer from './reducers/country';
import assortmentReducer from './reducers/assortment';
import categoryReducer from './reducers/category';
import certificateReducer from './reducers/certificate';
import companyReducer from './reducers/company';
import cutReducer from './reducers/cut';
import deductionTypeReducer from './reducers/deduction_type';
import depositReducer from './reducers/deposit';
import divisionReducer from './reducers/division';
import essenceReducer from './reducers/essence';
import forestTypeReducer from './reducers/forest_type';
import forstControlReducer from './reducers/forst_control';
import hammeringStemReducer from './reducers/hammering_stem';
import hammeringReducer from './reducers/hammering';
import interventionTypeReducer from './reducers/intervention_type';
import lotReducer from './reducers/lot';
import lotOfSsaleReducer from './reducers/lot_of_sale';
import measurementPileReducer from './reducers/measurement_pile';
import measurementStemReducer from './reducers/measurement_stem';
import measurementReducer from './reducers/measurement';
import measurementPileMethodReducer from './reducers/measurement_pile_method';
import motiveReducer from './reducers/motive';
import ownerReducer from './reducers/owner';
import partnerReducer from './reducers/partner';
import parameterReducer from './reducers/parameters';
import priceTypeReducer from './reducers/price_type';
import qualityReducer from './reducers/quality';
import qualityUsageReducer from './reducers/quality_usage';
import qualityUsageClassReducer from './reducers/quality_usage_class';
import remarkReducer from './reducers/remark';
import roleReducer from './reducers/role';
import sort1Reducer from './reducers/sort1';
import sort2Reducer from './reducers/sort2';
import userReducer from './reducers/user';
import userCompanyReducer from './reducers/user_company';
import woodTypeReducer from './reducers/wood_type';
import actionReducer from './reducers/action';
import productTypeReducer from './reducers/product_type';
import productReducer from './reducers/product';
import saleAndPurchaseReducer from './reducers/sale_and_purchase';
import conditionsGenerales from './reducers/conditions_generales';
import { login } from './actions/auth';
import { refreshUser } from './actions/users';
import tokens from "./reducers/tokens";
import { ACTIONS } from './actions/_actions_types';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appReducer = combineReducers({
    form: formReducer,
    assortment: assortmentReducer,
    auth: authReducer,
    category: categoryReducer,
    certificate: certificateReducer,
    company: companyReducer,
    cuts: cutReducer,
    deductionType: deductionTypeReducer,
    department: departmentReducer,
    countries: countryReducer,
    deposit: depositReducer,
    division: divisionReducer,
    essence: essenceReducer,
    forestType: forestTypeReducer,
    forstControl: forstControlReducer,
    hammeringStem: hammeringStemReducer,
    hammerings: hammeringReducer,
    interventionType: interventionTypeReducer,
    lot: lotReducer,
    lots_of_sale: lotOfSsaleReducer,
    measurementPile: measurementPileReducer,
    measurementStem: measurementStemReducer,
    measurement: measurementReducer,
    measurementPileMethod: measurementPileMethodReducer,
    motive: motiveReducer,
    owner: ownerReducer,
    parameter: parameterReducer,
    partners: partnerReducer,
    priceType: priceTypeReducer,
    quality: qualityReducer,
    qualityUsage: qualityUsageReducer,
    qualityUsageClass: qualityUsageClassReducer,
    remark: remarkReducer,
    role: roleReducer,
    sort1: sort1Reducer,
    sort2: sort2Reducer,
    user: userReducer,
    userCompany: userCompanyReducer,
    woodType: woodTypeReducer,
    action: actionReducer,
    product_type: productTypeReducer,
    product: productReducer,
    sale_and_purchase: saleAndPurchaseReducer,
    conditions_generales: conditionsGenerales,
    tokens: tokens
})

const rootReducer = (state, action) => {
    if (action.type === ACTIONS.AUTH.CLEAR_AUTH) {
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
}

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk)),
);

// Hydrate the auth token from localStorage if it exist
const token = loadToken();
if (token) {
    let decodedToken = jwt_decode(token);
    if (!(token.split(" ")[0] === "Public")) {
        if (decodedToken.exp > Date.now() / 1000) {
            store.dispatch(refreshUser(token, decodedToken.id_user, localStorage.getItem('company')));
        } else {
            const remember = localStorage.getItem('remember');
            const email = localStorage.getItem('email');
            const password = localStorage.getItem('password');
            store.dispatch(login(
                email,
                password,
                remember
            ))
        }
    }
}

export default store;
