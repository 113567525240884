import {API_BASE_URL} from '../config';
import {post, get, put, del} from "./utils";

export const QUALITY_SUCCESS = 'QUALITY_SUCCESS';
export const fetchSuccess = data => ({
    type: QUALITY_SUCCESS,
    data
});

export const QUALITY_ERROR = 'QUALITY_ERROR';
export const fetchError = error => ({
    type: QUALITY_ERROR,
    error
});

export const postQuality = (quality) => (dispatch) => {
    return post(dispatch, `${API_BASE_URL}/qualities`, quality)
    .then(({quality}) => dispatch(fetchSuccess(quality)));
};

export const getQualities = () => (dispatch) => {
    return get(dispatch, `${API_BASE_URL}/qualities/`)
        .then(({qualities}) => dispatch(fetchSuccess(qualities)))
};

export const getQuality = (id_quality) => (dispatch) => {
    return get(dispatch, `${API_BASE_URL}/qualities/${id_quality}`)
        .then(({quality}) => dispatch(fetchSuccess(quality)));
};

export const putQuality = (quality) => (dispatch) => {
    return put(dispatch, `${API_BASE_URL}/qualities/${quality.id_quality}`, quality)
        .then(({quality}) => dispatch(fetchSuccess(quality)));
};

export const deleteQuality = (id_quality) => (dispatch) => {
    return del(dispatch, `${API_BASE_URL}/qualities/${id_quality}`)
        .then(({quality}) => dispatch(fetchSuccess(quality)));
};