export const ROLES = {
	//admin
	1: {
		coupe: "CRUD",
		martelage: "CRUD",
		cubage: "CRUD",
		cubage_tige_pile: "CRUD",
		depot: "CRUD",
		lot: "CRUD",
		partenaires: "CRUD",
		vente: "CRUD",
		utilisateurs: "CRUD",
		entreprises: "CRUD",
		categorie: "CRUD",
		certificats: "CRUD",
		deduction_type: "CRUD",
		departement: "CRUD",
		essence: "CRUD",
		type_de_foret: "CRUD",
		martelage_plant: "CRUD",
		type_intervention: "CRUD",
		tarif: "CRUD",
		qualite: "CRUD",
		remarque: "CRUD",
		role: "CRUD",
		tri: "CRUD",
		type_de_bois: "CRUD",
		assortiment: "CRUD",
		produit: "CRUD",
		type_de_produit: "CRUD",
		actions: "CRUD",
		conditions_generales: "CRUD",
		carte: "R",
		email: "CRUD",
		report: "CRUD",
		partage: "CRUD"
	},
	//garde forestier
	2: {
		coupe: "CRUD",
		martelage: "CRUD",
		cubage: "CRUD",
		cubage_tige_pile: "CRUD",
		depot: "CRUD",
		lot: "CRUD",
		partenaires: "CRUD",
		vente: "CRUD",
		utilisateurs: "CRU",
		entreprises: "RU",
		categorie: "R",
		certificats: "R",
		deduction_type: "R",
		departement: "R",
		essence: "R",
		type_de_foret: "R",
		martelage_plant: "CRUD",
		type_intervention: "R",
		tarif: "R",
		qualite: "R",
		remarque: "R",
		role: "R",
		tri: "R",
		type_de_bois: "R",
		assortiment: "R",
		produit: "CRUD",
		type_de_produit: "R",
		actions: "R",
		carte: "R",
		conditions_generales: "R",
		email: "C",
		report: "C",
		partage: ""
	},
	//testeur
	3: {
		coupe: "CRUD",
		martelage: "CRUD",
		cubage: "CRUD",
		cubage_tige_pile: "CRUD",
		depot: "CRUD",
		lot: "CRUD",
		partenaires: "CRUD",
		vente: "CRUD",
		utilisateurs: "CRU",
		entreprises: "R",
		categorie: "R",
		certificats: "R",
		deduction_type: "R",
		departement: "R",
		essence: "R",
		type_de_foret: "R",
		martelage_plant: "CRUD",
		type_intervention: "R",
		tarif: "R",
		qualite: "R",
		remarque: "R",
		role: "R",
		tri: "R",
		type_de_bois: "R",
		assortiment: "R",
		produit: "CRUD",
		type_de_produit: "R",
		actions: "R",
		carte: "R",
		conditions_generales: "R",
		email: "C",
		report: "C",
		partage: "CRUD"
	},
	//municipal
	4: {
		coupe: "R",
		martelage: "R",
		cubage: "R",
		cubage_tige_pile: "",
		depot: "",
		lot: "R",
		partenaires: "R",
		vente: "R",
		utilisateurs: "RU",
		entreprises: "R",
		categorie: "R",
		certificats: "R",
		deduction_type: "",
		departement: "R",
		essence: "R",
		type_de_foret: "R",
		martelage_plant: "R",
		type_intervention: "R",
		tarif: "R",
		qualite: "",
		remarque: "",
		role: "",
		tri: "",
		type_de_bois: "",
		assortiment: "",
		produit: "R",
		type_de_produit: "R",
		actions: "",
		carte: "",
		conditions_generales: "R",
		email: "C",
		report: "C",
		partage: ""
	},
	//inspecteur cantonal
	5: {
		coupe: "R",
		martelage: "R",
		cubage: "R",
		cubage_tige_pile: "",
		depot: "",
		lot: "",
		partenaires: "",
		vente: "",
		utilisateurs: "RU",
		entreprises: "",
		categorie: "R",
		certificats: "",
		deduction_type: "",
		departement: "R",
		essence: "R",
		type_de_foret: "R",
		martelage_plant: "R",
		type_intervention: "R",
		tarif: "R",
		qualite: "",
		remarque: "",
		role: "",
		tri: "",
		type_de_bois: "",
		assortiment: "",
		produit: "R",
		type_de_produit: "R",
		actions: "",
		carte: "",
		conditions_generales: "R",
		email: "",
		report: "",
		partage: ""
	},
	//contremaitre
	6: {
		coupe: "CRU",
		martelage: "CRU",
		cubage: "CRU",
		cubage_tige_pile: "CRUD",
		depot: "",
		lot: "R",
		partenaires: "",
		vente: "",
		utilisateurs: "RU",
		entreprises: "",
		categorie: "R",
		certificats: "",
		deduction_type: "R",
		departement: "R",
		essence: "R",
		type_de_foret: "R",
		martelage_plant: "CRUD",
		type_intervention: "R",
		tarif: "R",
		qualite: "R",
		remarque: "R",
		role: "",
		tri: "R",
		type_de_bois: "R",
		assortiment: "R",
		produit: "CRU",
		type_de_produit: "R",
		actions: "",
		carte: "",
		conditions_generales: "R",
		email: "",
		report: "",
		partage: ""
	},
	//secretaire
	7: {
		coupe: "",
		martelage: "",
		cubage: "",
		cubage_tige_pile: "",
		depot: "",
		lot: "",
		partenaires: "",
		vente: "CRUD",
		utilisateurs: "RU",
		entreprises: "",
		categorie: "",
		certificats: "",
		deduction_type: "",
		departement: "",
		essence: "",
		type_de_foret: "",
		martelage_plant: "",
		type_intervention: "",
		tarif: "",
		qualite: "",
		remarque: "",
		role: "",
		tri: "",
		type_de_bois: "",
		assortiment: "",
		produit: "",
		type_de_produit: "",
		actions: "",
		conditions_generales: "R",
		email: "",
		report: "",
		partage: ""
	},
	//lecteur (transporteur)
	8: {
		coupe: "",
		martelage: "",
		cubage: "",
		cubage_tige_pile: "",
		depot: "",
		lot: "R",
		partenaires: "R",
		vente: "",
		utilisateurs: "RU",
		entreprises: "",
		categorie: "",
		certificats: "R",
		deduction_type: "",
		departement: "",
		essence: "",
		type_de_foret: "",
		martelage_plant: "",
		type_intervention: "",
		tarif: "",
		qualite: "",
		remarque: "",
		role: "",
		tri: "",
		type_de_bois: "",
		assortiment: "",
		produit: "",
		type_de_produit: "",
		actions: "",
		carte: "R",
		conditions_generales: "R",
		email: "",
		report: "",
		partage: ""
	},
	//all
	9: {
		coupe: "",
		martelage: "",
		cubage: "",
		cubage_tige_pile: "",
		depot: "",
		lot: "R",
		partenaires: "R",
		vente: "",
		utilisateurs: "RU",
		entreprises: "",
		categorie: "",
		certificats: "R",
		deduction_type: "",
		departement: "",
		essence: "",
		type_de_foret: "",
		martelage_plant: "",
		type_intervention: "",
		tarif: "",
		qualite: "",
		remarque: "",
		role: "",
		tri: "",
		type_de_bois: "",
		assortiment: "",
		produit: "",
		type_de_produit: "",
		actions: "",
		carte: "",
		conditions_generales: "R",
		email: "",
		report: "",
		partage: ""
	},
};
