import {API_BASE_URL} from '../config';
import { del, get, post, put } from './utils';

export const ESSENCE_SUCCESS = 'ESSENCE_SUCCESS';
export const fetchSuccess = data => ({
    type: ESSENCE_SUCCESS,
    data
});

export const ESSENCE_ERROR = 'ESSENCE_ERROR';
export const fetchError = error => ({
    type: ESSENCE_ERROR,
    error
});

export const postEssence = (essence) => (dispatch, getState) => {
    return post(dispatch, `${API_BASE_URL}/essences`, essence)
    .then(({essence}) => dispatch(fetchSuccess(essence)));
};

export const getEssences = () => (dispatch, getState) => {
    return get(dispatch, `${API_BASE_URL}/essences/`)
        .then(({essences}) => dispatch(fetchSuccess(essences)));
};

export const getEssence = (id_essence) => (dispatch, getState) => {
    return get(dispatch, `${API_BASE_URL}/essences/${id_essence}`)
        .then(({essence}) => dispatch(fetchSuccess(essence)));
};

export const putEssence = (essence) => (dispatch, getState) => {
    return put(dispatch, `${API_BASE_URL}/essences/${essence.id_essence}`, essence)
        .then(({essence}) => dispatch(fetchSuccess(essence)));
};

export const deleteEssence = (id_essence) => (dispatch, getState) => {
    return del(dispatch, `${API_BASE_URL}/essences/${id_essence}`)
        .then(({essence}) => dispatch(fetchSuccess(essence)));
};

// Category
export const getCategories = (id_essence) => (dispatch, getState) => {
    return get(dispatch, `${API_BASE_URL}/essences/${id_essence}/categories`)
        .then(({categories}) => dispatch(fetchSuccess(categories)));
};

export const postCategory = (id_essence, category) => (dispatch, getState) => {
    return post(dispatch, `${API_BASE_URL}/essences/${id_essence}/categories`, category)
        .then(({category}) => dispatch(fetchSuccess(category)));
};