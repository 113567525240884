import {API_BASE_URL} from '../config';
import { del, get, post, put } from './utils';

export const INTERVENTION_TYPE_SUCCESS = 'INTERVENTION_TYPE_SUCCESS';
export const fetchSuccess = data => ({
    type: INTERVENTION_TYPE_SUCCESS,
    data
});

export const INTERVENTION_TYPE_ERROR = 'INTERVENTION_TYPE_ERROR';
export const fetchError = error => ({
    type: INTERVENTION_TYPE_ERROR,
    error
});

export const postInterventionType = (intervention_type) => (dispatch, getState) => {
    return post(dispatch, `${API_BASE_URL}/intervention_types`, intervention_type)
        .then(({intervention_type}) => dispatch(fetchSuccess(intervention_type)));
};

export const getInterventionTypes = () => (dispatch) => {
    return get(dispatch, `${API_BASE_URL}/intervention_types/`)
        .then((i) => {
            console.log("intervention", i);
            dispatch(fetchSuccess(i.intervention_types))
    });
};

export const getInterventionType = (id_intervention_type) => (dispatch, getState) => {
    return get(dispatch, `${API_BASE_URL}/intervention_types/${id_intervention_type}`)
        .then(({intervention_type}) => dispatch(fetchSuccess(intervention_type)));
};

export const putInterventionType = (intervention_type) => (dispatch, getState) => {
    return put(dispatch, `${API_BASE_URL}/intervention_types/${intervention_type.id_intervention_type}`, intervention_type)
        .then(({intervention_type}) => dispatch(fetchSuccess(intervention_type)));
};

export const deleteInterventionType = (id_intervention_type) => (dispatch, getState) => {
    return del(dispatch, `${API_BASE_URL}/intervention_types/${id_intervention_type}`)
        .then(({intervention_type}) => dispatch(fetchSuccess(intervention_type)));
};