import { ACTIONS } from "../actions/_actions_types";

const initialState = {
    data: '',
    all: [],
    error: null,
    current: [],
    searched: []
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.USERS.GET_ALL:
            return {
                ...state,
                all: action.payload.app_users,
            };
        case ACTIONS.USERS.GET:
            return {
                ...state,
                current: action.payload.app_user
            };
        case ACTIONS.USERS.DELETE:
            return state;
        case ACTIONS.USERS.SEARCH:
            return {
                ...state,
                searched: action.payload.data
            };
        case ACTIONS.USERS.PUT:
            return {
                ...state,
                all: [
                    ...state.all.filter(app_user => app_user.id_user != action.payload.app_user.id_user),
                    action.payload.app_user
                ]
            };
        case ACTIONS.USERS.POST:
            return { ...state, all: [...state.all, action.payload] }
        case ACTIONS.USERS.SUCCESS:
            return {
                ...state,
                data: action.data,
                error: null
            };
        case ACTIONS.USERS.ERROR:
            return {
                ...state,
                error: action.error
            };
    }
    return state;
}