// import {HAMMERING_SUCCESS, HAMMERING_ERROR} from '../actions/hammering';
import { ACTIONS } from '../actions/_actions_types';

let initialState = {
    all: {},
    current: {},
    searched: {}
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.HAMMERING.GET_ALL:
            return {
                ...state,
                all: action.payload,
                searched: [],
            };
        case ACTIONS.HAMMERING.POST:
            return {
                ...state,
                all: [action.payload, ...state.all]
            };
        case ACTIONS.HAMMERING.PUT:
            return {
                ...state,
                all: [
                    ...state.all.filter(hammering => hammering.id_hammering != action.payload.hammering.id_hammering),
                    action.payload.hammering
                ]
            };
        case ACTIONS.HAMMERING.DELETE:
            return {
                ...state,
                all: state.all.filter(hammering => hammering.id_hammering != action.payload.id)
            };
        case ACTIONS.HAMMERING.GET:
            return {
                ...state,
                current: action.payload
            };
        case ACTIONS.HAMMERING.SEARCH:
            return {
                ...state,
                searched: action.payload.data
            };
        case ACTIONS.ERROR:
            alert(action.payload.data);
            return state;
        default:
            return state;
    }
}
